<template>
  <!-- 404 -->
  <section class="sect-404 py-14 md:my-14">
    <div class="max-w-5xl px-4 mx-auto sm:px-6">
      <div class="text-center 404-text">
        <div class="mb-6 text-6xl font-bold md:text-9xl text-blue">404</div>
        <p class="mb-6 text-xl font-bold md:text-2xl">Page not found</p>
        <div class="flex justify-center">
          <GreenBtn name="Index" text="Go back Home" />
        </div>
      </div>
    </div>
  </section>
  <!-- Space -->
  <div class="md:py-14"></div>
</template>

<script>
import GreenBtn from "@/components/GreenBtn.vue";
export default {
  name: "Page404",
  components: { GreenBtn },
};
</script>

<style scoped>
.sect-404 {
  background-image: url("../assets/images/page-404.svg");
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}
</style>
